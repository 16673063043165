import Button from 'components/Button/Button';
import LogoutIcon from 'components/Icon/assets/LogoutIcon';
import MenuIcon from 'components/Icon/assets/Menu';
import NotificationIcon from 'components/Icon/assets/NotificationIcon';
import Profile from 'components/Icon/assets/Profile';
import UserIcon2 from 'components/Icon/assets/UserIcon2';
import Modal from 'components/Modal';
import { languageConstant } from 'constants/common.constant';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'reduxStore/slices/authSlice';
import { setLanguage, useLanguage } from 'reduxStore/slices/languageSlice';
import { toggleSidebar } from 'reduxStore/slices/sidebarSlice';
import store from 'reduxStore/store';
import { logout } from 'utils';

const Header = () => {
  const { language, defaultLanguage } = useSelector(useLanguage);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useSelector(getAuth);
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  return (
    <header className="bg-white p-4 py-3 flex justify-between relative border-b border-gray-200">
      <Modal modalEl={modalRef} setIsOpen={setIsOpen} />
      <div className="flex items-center">
        <button
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <MenuIcon className="fill-primary" />
        </button>
      </div>
      <div className="flex gap-3">
        <div className="flex rounded-full gap-1 bg-lightGray items-center">
          <Button
            className={`rounded-full px-4 cursor-pointer h-full w-full flex items-center ${
              (language === languageConstant.EN ||
                (!language && defaultLanguage === languageConstant.EN)) &&
              'bg-secondaryLight'
            }`}
            onClickHandler={() =>
              dispatch(setLanguage({ language: languageConstant.EN }))
            }
          >
            EN
          </Button>
          <Button
            className={`rounded-full px-4 cursor-pointer h-full w-full flex items-center ${
              (language === languageConstant.ES ||
                (!language && defaultLanguage === languageConstant.ES)) &&
              'bg-secondaryLight'
            }`}
            onClickHandler={() =>
              dispatch(setLanguage({ language: languageConstant.ES }))
            }
          >
            ES
          </Button>
        </div>
        <button className="border border-primaryLight rounded-full p-2">
          <NotificationIcon />
        </button>
        <button
          className="bg-white rounded-full p-1 text-2xl font-semibold"
          onClick={() => setIsOpen(true)}
        >
          <Profile />
        </button>
      </div>

      <div
        className={`flex flex-col absolute top-[65px] right-5 bg-white text-black rounded-md z-10 shadow-lg border gap-2  px-2 py-2 ${
          !isOpen && 'hidden'
        }`}
        ref={modalRef}
      >
        <span className="flex items-center gap-1">
          <UserIcon2 /> {user?.first_name ?? 'Profile'} {user?.last_name}
        </span>
        <Button
          onClickHandler={() => logout(store)}
          className="text-red-500 hover:bg-red-500 hover:text-white rounded-md flex text-lg items-center"
        >
          <LogoutIcon className="mr-2" /> Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
