import CMSAccessDevice from '../HomeCMS/Components/CMSAccessDevice';
import CMSCTA from '../HomeCMS/Components/CMSCTA';
import CMSCourseList from './CMSCourseList';
import CourseBanner from './CourseBanner';

const CMSCourse = () => {
  return (
    <>
      <CourseBanner />
      <CMSCourseList />
      <CMSCTA
        variant="2"
        linkText="Sign me up!"
        leftImagePath="/images/three-girl-hand-gesture.png"
        title="When you enroll, you will have access to lecture videos, skills practice videos, quizzes, assignments, and our exclusive platform, The Lobby!"
      />
      <CMSAccessDevice />
    </>
  );
};

export default CMSCourse;
