import Loaders from '../Loaders';
import './style/index.css';
import { IButtonProps } from './types';

const getButtonClasses = (variant: string) => {
  switch (variant) {
    case 'black':
      return 'button black ';
    case 'blackBorder':
      return 'button blackBorder ';
    case 'PrimaryWoodBorder':
      return 'button PrimaryWoodBorder ';
    case 'PrimaryWood':
      return 'button PrimaryWood ';
    case 'RedOpacity':
      return 'button RedOpacity ';
    case 'Red':
      return 'button Red ';
    case 'GreenOpacity':
      return 'button GreenOpacity ';
    case 'Green':
      return 'button Green ';
    case 'OffWhite':
      return 'button OffWhite ';
    default:
      return '';
  }
};

const Button = ({
  small,
  className,
  children,
  type = 'button',
  disabled,
  onClickHandler,
  variants,
  value,
  name,
  isLoading,
  customStyle,
  tooltipText,
  parentClass,
}: IButtonProps) => {
  return (
    <div className={parentClass || ''}>
      {onClickHandler || type !== 'button' ? (
        <button
          type={type}
          style={customStyle}
          disabled={disabled || isLoading}
          className={`${tooltipText && 'relative group'} ${
            variants && getButtonClasses(variants)
          }  ${small && ' !py-1.5 !px-2.5 !font-normal '} ${
            isLoading && 'flex justify-center items-center gap-0.5'
          }  ${className ?? ''}`}
          onClick={onClickHandler}
          name={name}
        >
          {value}
          {children}
          {isLoading && <Loaders className="ms-1" />}
        </button>
      ) : (
        <span
          style={customStyle}
          className={` ${tooltipText && 'relative group'} ${
            variants && getButtonClasses(variants)
          } ${small && '!py-1.5 !px-2.5 !font-normal '} ${className ?? ''}`}
        >
          {value}
          {children}
        </span>
      )}
    </div>
  );
};

export default Button;
