import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';
import { SideBarType } from 'reduxStore/types';

const initialState: SideBarType = {
  isOpen: true,
  isActive: 'Dashboard',
};

const slice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state: SideBarType) {
      state.isOpen = !state.isOpen;
    },
    hideSidebar(state: SideBarType) {
      state.isOpen = false;
    },
    showSidebar(state: SideBarType) {
      state.isOpen = true;
    },
    activeSidebar(state: SideBarType, action: PayloadAction<SideBarType>) {
      state.isActive = action.payload.isActive;
    },
    clearActiveSidebar(state: SideBarType) {
      state.isActive = null;
    },
  },
});

export const { reducer } = slice;
export const SidebarSelector = (state: RootStateType) => state.sidebar.isOpen;
export const ActiveSelector = (state: RootStateType) => state.sidebar.isActive;
export const {
  toggleSidebar,
  hideSidebar,
  showSidebar,
  activeSidebar,
  clearActiveSidebar,
} = slice.actions;

export default slice;
