import Image from 'components/Image';
import { Link } from 'react-router-dom';
import './index.css';

interface CMSCTAProps {
  leftImagePath?: string;
  title?: string;
  linkText?: string;
  linkURL?: string;
  rightImagePath?: string;
  variant?: '1' | '2' | '3' | '';
}

const CMSCTA = ({
  leftImagePath,
  title,
  linkText,
  linkURL,
  rightImagePath,
  variant,
}: CMSCTAProps) => {
  return (
    <>
      <section
        className={`home-cta fill ${variant === '1' ? 'v1' : variant === '2' ? 'v2' : variant === '3' ? 'v3' : ''}`}
      >
        <div className="container">
          {rightImagePath && (
            <Image
              src={`${rightImagePath || '/images/hand-sign.png'}`}
              imgClassName="hand-sign-img"
            />
          )}
          <div className="wrapper">
            <div className="left-part">
              <div className="img-wrap">
                <Image src={leftImagePath || '/images/asl-web-and-mobile.png'} />
              </div>
            </div>
            <div className="right-part">
              <div className="inner">
                {variant === '2' ? (
                  <p className="big-title">{title || 'lorem ipsum'}</p>
                ) : (
                  <h2>
                    {title ||
                      "Don't wait to get started. Take ASL 1 for free today!"}
                  </h2>
                )}
                <div className="btn btn-black-border">
                  <Link to={`${linkURL}`}>
                    {linkText || 'Explore our courses'}
                    <Image iconName="arrowRight" iconClassName="w-4 h-4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CMSCTA;
