import Image from 'components/Image';
import './index.css';

const CMSAccessDevice = () => {
  return (
    <section className="access-device">
      <div className="container">
        <div className="wrapper">
          <div className="left-part">
            <div className="section-title">
              <span className="small-title">Access From any Device</span>
              <h2>Anywhere. At Any Time.</h2>
              <p>
                Use The ASL Shop to learn American Sign Language (ASL) on your
                smartphone, tablet or laptop. No matter where you are, your learning
                progress is constantly being synced across all your devices.
              </p>
            </div>
            <div className="store-wrap">
              <div className="store-item">
                <Image src="/images/apple-store.svg" />
              </div>
              <div className="store-item">
                <Image src="/images/google-play-store.svg" />
              </div>
            </div>
          </div>
          <div className="right-part">
            <Image src="/images/asl-web-and-mobile.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CMSAccessDevice;
