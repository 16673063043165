import ASLIcon from 'components/Icon/assets/ASLIcon';
import DashboardIcon from 'components/Icon/assets/DashboardIcon';
import UserIcon from 'components/Icon/assets/UserIcon';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SidebarSelector } from 'reduxStore/slices/sidebarSlice';

const sidebarElements = [
  {
    label: 'Dashboard',
    to: '/dashboard',
    icon: <DashboardIcon />,
  },
  {
    label: 'Manage Users',
    to: '/manage_users',
    icon: <UserIcon />,
  },
];

const Sidebar = () => {
  const isSidebarOpen = useSelector(SidebarSelector);
  return (
    <aside className="max-w-64 bg-white h-full border-r border-gray-200">
      <nav className="flex flex-col">
        <div className="p-4">
          <ASLIcon
            className="w-max"
            width={isSidebarOpen ? '223' : '60'}
            height={isSidebarOpen ? '42' : '25'}
          />
          {/* <hr className="my-4" /> */}
        </div>
        <span className="text-primary bg-lightGray px-4">ADMIN</span>
        <div className="flex flex-col mt-4 mx-4 text-primary">
          {sidebarElements.map((element) => (
            <NavLink
              key={element.label}
              to={element.to}
              className={({ isActive }) =>
                `p-4 flex items-center font-400 text-primary gap-2 ${
                  isActive ? 'bg-black text-white rounded-md' : ''
                }`
              }
            >
              {element.icon}
              {isSidebarOpen && element.label}
            </NavLink>
          ))}
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
