import Image from 'components/Image';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const CourseBanner = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <section className="course-banner">
      <div className="container">
        <div className="wrapper">
          <div className="left-part">
            <div className="section-title">
              <span className="hashtag-label">#start aSL courses</span>
              <h1 className="h2">Self-Paced Courses</h1>
              <p>
                Perhaps you're too busy to stick to a rigid schedule of twice a week
                live learning and want to learn as you go when you have down-time. If
                that sounds like you, sign up for our self-paced classes where you
                can access all class content online in a self-paced manner. Upon
                sign-up you will be granted access to our full collection of
                lectures, assignments, activities, and quizzes. You will get
                extensive educational content, as well as access to a rich, exclusive
                community where you'll develop a network with your peers, our
                founder, and team members!
              </p>
            </div>
            <div className="btn btn-black-border">
              <Link to="./">
                Sign me up! <Image iconName="arrowRight" />
              </Link>
            </div>
          </div>
          <div className="right-part">
            <div className="video-wrap">
              <Image src="/images/course-card-1.png" />
              <video
                ref={videoRef}
                width="600"
                src="/videos/banner.mp4"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                muted
                autoPlay
                loop
              />
            </div>
            <p className="note">
              Here is a glimpse of what our Self Paced Courses look like!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseBanner;
