import Layout from 'components/Layout';
import SiteLoader from 'components/Loaders/SiteLoader';
import { PRIVATE_CMS_NAVIGATION } from 'constants/navigation.constant';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary as ErrorBoundaryDependency } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { currentPageCount } from 'reduxStore/slices/paginationSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';
import ErrorBoundary from '../pages/ErrorBoundary';

type Props = {
  children: JSX.Element;
};

// Layout for Authenticated Pages
const RequiresAuth = (props: Props) => {
  const { children } = props;
  const authToken = useSelector(getAuthToken);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentPageCount({ currentPage: 1 }));
  }, [location]);

  if (!authToken) {
    return <Navigate to={PRIVATE_CMS_NAVIGATION.home.view.path} />;
  }

  return (
    // Setup error boundary to capture runtime errors
    <ErrorBoundaryDependency FallbackComponent={ErrorBoundary}>
      <Layout>
        <Suspense fallback={<SiteLoader />}>{children}</Suspense>
      </Layout>
    </ErrorBoundaryDependency>
  );
};

export default RequiresAuth;
