import Image from 'components/Image';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import '../CourseBanner/index.css';
import './index.css';

const courseData = [
  {
    id: 0,
    title: 'Zoom Classes',
    description:
      "Perhaps you're too busy to stick to a rigid schedule of twice a week live learning and want to learn as you go when you have down-time. If that sounds like you,sign up for our self-paced classes where you can access all class content online in a self-paced manner. Upon sign-up you will be granted access to our full collection of lectures,assignments,activities,and quizzes. You will get extensive educational content,as well as access to a rich,exclusive community where you'll develop a network with your peers,our founder,and team members!",
    links: [
      {
        id: 0,
        LinkText: 'View Schedule',
        LinkURL: './',
      },
    ],
    badge: '',
    imagePath: '/images/course-card-1.png',
    videoPath: '/videos/banner.mp4',
  },
  {
    id: 1,
    title: 'ASL Mini Lessons',
    description:
      "Perhaps you're too busy to stick to a rigid schedule of twice a week live learning and want to learn as you go when you have down-time. If that sounds like you, sign up for our self-paced classes where you can access all class content online in a self-paced manner. Upon sign-up you will be granted access to our full collection of lectures, assignments, activities, and quizzes. You will get extensive educational content, as well as access to a rich, exclusive community where you'll develop a network with your peers, our founder, and team members!",
    links: [
      {
        id: 0,
        LinkText: 'Sign me up!',
        LinkURL: './',
      },
    ],
    badge: 'Free',
    imagePath: '/images/course-card-2.png',
    videoPath: '/videos/banner.mp4',
  },
  {
    id: 2,
    title: 'Zoom Classes',
    description:
      "Perhaps you're too busy to stick to a rigid schedule of twice a week live learning and want to learn as you go when you have down-time. If that sounds like you,sign up for our self-paced classes where you can access all class content online in a self-paced manner. Upon sign-up you will be granted access to our full collection of lectures,assignments,activities,and quizzes. You will get extensive educational content,as well as access to a rich,exclusive community where you'll develop a network with your peers,our founder,and team members!",
    links: [
      {
        id: 0,
        LinkURL: './',
        LinkText: 'Live Assessment',
      },
      {
        id: 1,
        LinkURL: './',
        LinkText: 'Self Assessment',
      },
    ],
    badge: '',
    imagePath: '/images/course-card-3.png',
    videoPath: '/videos/banner.mp4',
  },
  {
    id: 3,
    title: 'Zoom Classes',
    description:
      "Perhaps you're too busy to stick to a rigid schedule of twice a week live learning and want to learn as you go when you have down-time. If that sounds like you,sign up for our self-paced classes where you can access all class content online in a self-paced manner. Upon sign-up you will be granted access to our full collection of lectures,assignments,activities,and quizzes. You will get extensive educational content,as well as access to a rich,exclusive community where you'll develop a network with your peers,our founder,and team members!",
    links: [
      {
        id: 0,
        LinkText: 'View Schedule',
        LinkURL: './',
      },
    ],
    badge: '',
    imagePath: '/images/course-card-1.png',
    videoPath: '/videos/banner.mp4',
  },
];

const CMSCourseList = () => {
  const videoRef2 = useRef<HTMLVideoElement | null>(null);

  const handleMouseEnter = () => {
    if (videoRef2.current) {
      videoRef2.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef2.current) {
      videoRef2.current.pause();
    }
  };

  return (
    <section className="course-list course-banner">
      {courseData.map((e, index) => {
        return (
          <>
            <div key={index} className="course-list-item">
              <div className="container">
                <div className="wrapper">
                  <div className="left-part">
                    <div className="section-title">
                      <h2>{e.title}</h2>
                      <p>{e.description}</p>
                      <div className="btn-wrap">
                        {e.links.map((el, elindex) => {
                          return (
                            <>
                              <div key={elindex} className="btn btn-black-border">
                                <Link to={el.LinkURL}>
                                  {el.LinkText} <Image iconName="arrowRight" />
                                </Link>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="right-part">
                    <div className="video-wrap">
                      {e.badge && <span className="price-label">{e.badge}</span>}
                      <Image src={e.imagePath} />
                      <video
                        ref={videoRef2}
                        width="600"
                        src={e.videoPath}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        muted
                        autoPlay
                        loop
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </section>
  );
};

export default CMSCourseList;
