export const languageConstant: { [key: string]: string } = {
  ES: 'es',
  EN: 'en',
  DEFAULT: 'en',
};

export const FeatureEnum = {
  Dashboard: 'Dashboard',
  Users: 'Users',
  Home: 'Home',
};

export const PermissionEnum = {
  Update: 'Update',
  Delete: 'Delete',
  Create: 'Create',
  View: 'View',
};

export const Roles = Object.freeze({
  Admin: 'Admin',
});

export enum ProvidersEnum {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  MICROSOFT = 'microsoft',
}
