import { FeatureEnum, PermissionEnum } from './common.constant';

export const PUBLIC_NAVIGATION = Object.freeze({
  login: '/auth/login',
  twoFA: '/auth/2fa',
  register: '/auth/register',
  enrollMFA: '/auth/enroll-mfa',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/auth/resetPassword',
  somethingWentWrong: '/something-went-wrong',

  // CMS
  CMSHome: '/',
  CMSCourse: '/cms-course',
  CMSVirtualClasses: '/cms-v-classes',
});

export const PRIVATE_NAVIGATION = Object.freeze({
  mfa: '/mfa',
  dashboard: {
    view: {
      path: '/dashboard',
      feature: FeatureEnum.Dashboard,
      permission: PermissionEnum.View,
    },
  },
  users: {
    view: {
      path: '/manage_users',
      feature: FeatureEnum.Users,
      permission: PermissionEnum.View,
    },
  },
});

export const PRIVATE_CMS_NAVIGATION = Object.freeze({
  home: {
    view: {
      path: '/home',
      feature: FeatureEnum.Home,
      permission: PermissionEnum.View,
    },
  },
});
